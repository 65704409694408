import React, { useEffect, useState } from "react"
import {
  Form,
  FormGroup,
  FormText,
  Col,
  Row,
  CustomInput,
  FormFeedback,
  Label,
} from "reactstrap"
import { FileUploader } from "react-drag-drop-files"
import {
  CheckPicker,
  CustomProvider,
  SelectPicker,
  Input,
  Button,
} from "rsuite"
import Editor from "common/Editor"
import { connect, useDispatch } from "react-redux"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { useHistory } from "react-router-dom"
import { ShowNotification } from "common/ShowNotification"
import { useLocation } from "react-router-dom"
import {
  getAllApproveUser,
  getAllExistingNoticeIdName,
  getBatchByNoticeId,
  getChangeUserSelect,
} from "store/actions"

function AutoDownloadComponents(props) {
  const location = useLocation()

  const requestData = {
    user_id: null,
    notice_id: null,
    batch_name: null,
    withTrackData: "No",
    noticeNameAs: "NoticeId",
    scheduled_on: null,
    report_type: "All",
    refrenceCsv: null,
    token: props?.token,
  }

  const [request, setRequest] = useState(requestData)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const history = useHistory()
  const dispatch = useDispatch()

  const handleFileChange = file => {
    setRequest({ ...request, refrenceCsv: file })
  }

  const selectdatanotice = props?.noticeData?.map(item => ({
    value: item?.notice_id,
    label: item?.notice_type,
  }))

  const selectdatauser = props?.userdata?.map(item => ({
    label: item?.organization_name,
    value: item?.id,
  }))

  const selectdatabatch = props?.batchData?.map(item => ({
    value: item?.batchName,
    label: item?.batchName,
  }))

  const selectNoticeNameAs = [
    { 
      label: "NoticeId",
      value: "NoticeId",
    },
    { 
      label: "ReferenceId",
      value: "ReferenceId",
    }
  ]

  const selectWithTrackData = [
    { 
      label: "Yes",
      value: "Yes",
    },
    { 
      label: "No",
      value: "No",
    }
  ]

  //const [withTrackData, setWithTrackData] = useState("No");
  //const [noticeNameAs, setNoticeNameAs] = useState("NoticeId");

  const selectReportData = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Preliminary",
      value: "Preliminary",
    },
    {
      label: "Interim",
      value: "Interim",
    },
    {
      label: "Final",
      value: "Final",
    },
  ]

  // const handleSubmit = e => {
  //   e.preventDefault()
  //   // request?.bulk_csv && setShowError({ ...showError, bulk_csv: true })
  //   request?.notice_id &&
  //     request?.withTrackData &&
  //     request?.noticeNameAs &&
  //     request?.batch_name &&
  //     request?.scheduled_on && [
  //       props?.setApiAction({ loading: true }),
  //       ApiCall(request, "addAutoDownloadRequest", false, result => {
  //         if (result?.data?.response === "success") {
  //           ShowNotification(result?.data?.message, "success")
  //           // history.push("/admin/existingnotice")
  //           setRequest({
  //             ...request,
  //             notice_id: null,
  //             user_id: null,
  //             batch_name: null,
  //             scheduled_on: null,
  //             refrenceCsv: null,
  //             withTrackData: "No",
  //             noticeNameAs: "NoticeId",
  //           })
  //           Array.from(document.querySelectorAll(["input", "select"])).forEach(
  //             input => (input.value = "")
  //           )
  //           props?.setApiAction({
  //             ...props?.apiAction,
  //             action: !props?.apiAction?.action,
  //           })
  //         } else {
  //           ShowNotification(result?.data?.message, "danger")
  //         }
  //         props?.setApiAction({ ...props?.apiAction, loading: false })
  //       }),
  //     ]
  // }

  const handleSubmit = e => {
    e.preventDefault();
  
    if ((request?.user_id && request?.refrenceCsv && request?.scheduled_on) || 
        (request?.user_id && request?.notice_id && request?.batch_name && request?.scheduled_on)) {
      props?.setApiAction({ loading: true });
  
      ApiCall(request, "addAutoDownloadRequest", false, result => {
        if (result?.data?.response === "success") {
          ShowNotification(result?.data?.message, "success");
          // history.push("/admin/existingnotice")
          setRequest({
            ...request,
            notice_id: null,
            user_id: null,
            batch_name: null,
            scheduled_on: null,
            refrenceCsv: null,
            withTrackData: "No",
            noticeNameAs: "NoticeId",
          });
  
          Array.from(document.querySelectorAll(["input", "select"])).forEach(
            input => (input.value = "")
          );
  
          props?.setApiAction({
            ...props?.apiAction,
            action: !props?.apiAction?.action,
          });
        } else {
          ShowNotification(result?.data?.message, "danger");
        }
        props?.setApiAction({ ...props?.apiAction, loading: false });
      });
    } else {
      ShowNotification("Please fill all required fields before submitting.", "danger");
    }
  };
  

  const AllFetchUserBySearch = (e) => {
    const requestPayload = {
      token: props?.token,
      search_text: e,
    }
    // console.log(e.length);
    if(e.length >= 3) {
      dispatch(getChangeUserSelect(requestPayload))
    }
  }

  // useEffect(() => {
  //   const requestPayload = {
  //     token: props?.token,
  //   }
  //   dispatch(getAllApproveUser(requestPayload))
  // }, []);

  useEffect(() => {
    setRequest({ ...request, notice_id: null })

    let formData = new FormData()
    formData.append("token", props?.token)
    formData.append("user_id", request?.user_id)

    dispatch(getAllExistingNoticeIdName(formData))
  }, [request?.user_id])

  useEffect(() => {
    setRequest({ ...request, batch_name: null })

    let formData = new FormData()
    formData.append("token", props?.token)
    formData.append("notice_id", request?.notice_id)

    dispatch(getBatchByNoticeId(formData))
  }, [request?.notice_id])

  // useEffect(() => {
  //   request?.notice_id && request?.batch_name && request?.scheduled_on
  //     ? setButtonDisabled(false)
  //     : setButtonDisabled(true)
  // }, [request])

  useEffect(() => {
    request?.user_id && request?.refrenceCsv && request?.scheduled_on
      ? setButtonDisabled(false)
      : request?.user_id && request?.notice_id && request?.batch_name && request?.scheduled_on
      ? setButtonDisabled(false)
      : setButtonDisabled(true);
  }, [request]);
  

  return (
    <div className="card">
      <div className="card-Body m-2">
        <Form method="post" onSubmit={handleSubmit}>
          <Row>
            <Col md={4}>
              <Label>Select User:</Label>
              <SelectPicker
                onSearch={(e) => AllFetchUserBySearch(e)}
                value={request?.user_id}
                block
                className="mb-3"
                data={selectdatauser}
                onChange={e => setRequest({ ...request, user_id: e })}
              />
            </Col>
            <Col md={4}>
              <Label>Select Notice:</Label>
              <SelectPicker
                value={request?.notice_id}
                block
                className="mb-3"
                data={selectdatanotice}
                onChange={e => setRequest({ ...request, notice_id: e })}
              />
            </Col>
            <Col md={4}>
              <Label>Select Batch:</Label>
              <SelectPicker
                value={request?.batch_name}
                className="mb-3"
                block
                data={selectdatabatch}
                onChange={e => setRequest({ ...request, batch_name: e })}
              />
            </Col>
            <Col md={4}>
              <Label>With Track Data:</Label>
              <SelectPicker
                value={request?.withTrackData}
                name="withTrackData"
                block
                className="mb-3"
                data={selectWithTrackData}
                onChange={e => setRequest({ ...request, withTrackData: e })}
              />
            </Col>
            <Col md={4}>
              <Label>Notice Name As:</Label>
              <SelectPicker
                value={request?.noticeNameAs}
                name="noticeNameAs"
                block
                className="mb-3"
                data={selectNoticeNameAs}
                onChange={e => setRequest({ ...request, noticeNameAs: e })}
              />
            </Col>
            <Col md={4}>
              <Label>Date & Time:</Label>
              <Input
                type="datetime-local"
                name="scheduled_on"
                id="scheduled_on"
                onChange={e => setRequest({ ...request, scheduled_on: e })}
                block
              />
            </Col>
            <Col md={4}>
              <Label>From Notice Id:</Label>
              <Input
                type="text"
                name="rangeFrom"
                id="rangeFrom"
                onChange={e => setRequest({ ...request, rangeFrom: e })}
                block
                placeholder="From Notice Id"
              />
            </Col>
            <Col md={4}>
              <Label>To Notice Id:</Label>
              <Input
                type="text"
                name="rangeTo"
                id="rangeTo"
                onChange={e => setRequest({ ...request, rangeTo: e })}
                block
                placeholder="To Notice Id"
              />
            </Col>
            <Col md={4}>
              <Label>Except Notice Id (comma separated):</Label>
              <Input
                type="text"
                name="rangeExcept"
                id="rangeExcept"
                onChange={e => setRequest({ ...request, rangeExcept: e })}
                block
                placeholder="IN12-123,IN12-234"
              />
              </Col>
              <Col md={4}>
              <Label>Upload Reference Id CSV:</Label>
              <FileUploader
                    name="file"
                    types={["csv"]}
                    
                    handleChange={handleFileChange}
                    onTypeError={e =>
                      e &&
                      setRequest({
                        ...request,
                        refrenceCsv: null,
                      })
                    }
                    
              />
            </Col>
            <Col md={4}>
              <Button
                type="submit"
                className="my-4"
                // color="green"
                color="primary"
                size="md"
                block
                disabled={buttonDisabled || props?.apiAction?.loading}
              >
                {props?.apiAction?.loading ? "Loading..." : "Submit"}
              </Button>{" "}
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  token: state.Login.token,
  noticeData: state.ExistingNoticeIdName.items,
  batchData: state.BatchByNoticeId.items,
  userdata: state.AllOnChangeUser.items,
})

export default connect(mapStateToProps)(AutoDownloadComponents)
