import FileDownloadIcon from "@rsuite/icons/FileDownload"
import axios from "axios"
import { ApiCall } from "common/ActionApiCall/ApiCall"
import { ApiRoute, RouteForShow } from "common/ApiRoute"
import Editor from "common/Editor"
import { SanitizeHtml } from "common/Editor/sanitizeHtml"
import { ShowNotification } from "common/ShowNotification"
import WhisperTooltip from "components/Common/Whisper/WhisperTooltip"
import React, { useEffect, useState } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { FileUploader } from "react-drag-drop-files"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import { Button, Form, FormGroup, Input, Label } from "reactstrap"
import { IconButton, SelectPicker, Toggle } from "rsuite"

import LetterHeadSetting from "../AddNewNotice/LetterHeadSetting"
import PdfViewer from "../PDFViewer/PdfViewer"

function NoticeForm(props) {
  const [open, setOpen] = useState(false)
  const [letterHead, setletterHead] = useState([{}])
  const handleLinkExpireChange = value => {
    props?.setRequest({ ...props?.request, willLinkExpire: value })
  }
  const handleSendAsChange = value => {
    props?.setRequest({ ...props?.request, send_as: value })
  }
  function closeModal(value) {
    setOpen(value)
  }
  const handleAutoGeneratePdfChange = value => {
    props?.setRequest({ ...props?.request, autoGeneratePdf: value })
  }
  const updaterequest = data => {
    props?.setRequest({
      ...props?.request,
      letterhead_configuration: JSON.stringify(data),
    })
  }
  const [Express, setExpress] = useState(false)
  // handleCommunicationModeChange  = value => {}
  const handleCommunicationModeChange = (mode, isChecked) => {
    let updatedRequest = { ...props?.request }
    switch (mode) {
      case 1: // Email
        updatedRequest.emailEnabled = isChecked ? 1 : 0
        break
      case 2: // SMS
        updatedRequest.smsEnabled = isChecked ? 1 : 0
        break
      case 3: // WhatsApp
        updatedRequest.whatsappEnabled = isChecked ? 1 : 0
        break
      case 4: // RPAD
        updatedRequest.rpadEnabled = isChecked ? 1 : 0
        break
      default:
        break
    }

    props?.setRequest(updatedRequest)
  }
  const validationCheck = () => {
    if (
      props?.request?.transmission_type === "API" ||
      props?.request?.transmission_type === "BOTH"
    ) {
      if (!props?.request?.letterhead_configuration) {
        ShowNotification("You Cannot convert File to API", "danger")
        return false
      }
    }
    if (props?.request?.notice_type.length >= 55) {
      ShowNotification("Maximum 55 characters allowed", "danger")
      return false
    } else {
      return true
    }
  }

  const handleSeparateWhatsappModeChange = (mode, isChecked) => {
    let updatedRequest = { ...props?.request }
    switch (mode) {
      case 1: // sent whatsapp text
        updatedRequest.sent_whatsapp_text = isChecked ? 1 : 0
        break
      case 2: // sent whatsapp pdf
        updatedRequest.sent_whatsapp_pdf = isChecked ? 1 : 0
        break
      case 3: // use organisation name
        updatedRequest.use_organization_name = isChecked ? 1 : 0
        break
      default:
        break
    }

    props?.setRequest(updatedRequest)
  }
  const history = useHistory()

  const handleSubmit = e => {
    e.preventDefault()
    // request?.bulk_csv && setShowError({ ...showError, bulk_csv: true })
    if (props?.request?.natureOfNotice === "USER") {
      const newRequest = {
        ...props?.request,
        draft_notice_html: SanitizeHtml(props?.request?.draft_notice_html),
      }
      props?.request?.token &&
        props?.request?.notice_type &&
        props?.request?.notice_desc &&
        props?.request?.draft_notice_html &&
        validationCheck() &&
        ApiCall(newRequest, "admin/edit-notice", false, result => {
          if (result?.data?.response === "success") {
            ShowNotification(result?.data?.message, "success")
            history.push("/admin/existingnotice")
          } else {
            ShowNotification(result?.data?.message, "danger")
          }
        })
    } else {
      props?.request?.token &&
        props?.request?.notice_type &&
        props?.request?.notice_desc &&
        ApiCall(props?.request, "admin/edit-proxy-notice", false, result => {
          if (result?.data?.response === "success") {
            ShowNotification(result?.data?.message, "success")
            history.push("/admin/existingnotice")
          } else {
            ShowNotification(result?.data?.message, "danger")
          }
        })
    }
  }

  const handlePreview = () => {
    const passData = {
      token: props?.request?.token,
      noticeContent: props?.request?.draft_notice_html,
    }
    ApiCall(passData, "previewNoticeContent", false, result => {
      if (result?.data?.response === "success") {
        ShowNotification(result?.data?.message, "success")
        const link = document.createElement("a")
        // link.target = "_blank"
        link.target = "_blank"
        link.href = result?.data?.url
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      } else {
        ShowNotification(result?.data?.message, "danger")
      }
    })
  }
  const [users, selectUsers] = useState("")
  const [notices, setNotices] = useState([])
  const [addedNotices, setAddedNotices] = useState([])

  const getLetterHeadById = id => {
    const formdata = new FormData()
    formdata.append("userId[]", id)
    axios.post(`${ApiRoute}letterheadByUserId`, formdata).then(res => {
      setletterHead(res.data.response)
    })
  }
  useEffect(() => {
    // Check if props?.request?.assignedUsers is defined and not empty
    if (props?.request?.assignedUsers) {
      // Update users state with the value from props
      selectUsers(props.request.assignedUsers)
    }
  }, [props?.request?.assignedUsers])
  useEffect(() => {
    if (users) {
      const payload = new FormData()
      payload.append("userId", users)
      axios
        .post(`${ApiRoute}getAssignedNoticeByUserId`, payload)
        .then(response => {
          // Extract assigned notice IDs for comparison
          const assignedNoticeIds = new Set(
            props?.request.assignedNotices.map(notice => notice.notice_id)
          )

          // Filter out assigned notices from the response data
          const availableNotices = response.data.response.filter(
            item =>
              !assignedNoticeIds.has(item.notice_id) &&
              item.nature_of_notice === "USER"
          )

          setNotices(
            availableNotices.map(item => ({
              notice_id: item.notice_id,
              notice_type: item.notice_name,
            }))
          )

          // Directly set assigned notices from props to state
          setAddedNotices(props?.request.assignedNotices)
        })
        .catch(error => {
          console.error("Error:", error)
        })
    }
  }, [users])
  useEffect(() => {
    props?.setRequest({
      ...props?.request,
      addedNotices: addedNotices.map(obj => obj.notice_id),
    })
  }, [addedNotices])

  const onDragEnd = result => {
    if (!result.destination) {
      return
    }

    const sourceList = result.source.droppableId
    const destinationList = result.destination.droppableId

    const draggedNotice =
      sourceList === "notices"
        ? notices[result.source.index]
        : addedNotices[result.source.index]

    if (sourceList === "notices" && destinationList === "addedNotices") {
      if (addedNotices.length === 0) {
        const updatedNotices = notices.filter(
          (notice, index) => index !== result.source.index
        )
        setNotices(updatedNotices)
        setAddedNotices(prevNotices => [...prevNotices, draggedNotice])
      } else {
        const firstNoticeId = addedNotices[0].notice_id
        const newNoticeId = draggedNotice.notice_id

        checkNoticeCondition(firstNoticeId, newNoticeId)
          .then(canAddNotice => {
            if (canAddNotice) {
              const updatedNotices = notices.filter(
                (notice, index) => index !== result.source.index
              )
              setNotices(updatedNotices)

              setAddedNotices(prevNotices => [...prevNotices, draggedNotice])
            } else {
              ShowNotification(
                "Can't add this notice. Please check the CSV format to ensure headers match the expected structure.",
                "danger"
              )
            }
          })
          .catch(error => {
            console.error("Error checking notice condition:", error)
          })
      }
    } else if (sourceList === "addedNotices" && destinationList === "notices") {
      const updatedAddedNotices = addedNotices.filter(
        (notice, index) => index !== result.source.index
      )
      setAddedNotices(updatedAddedNotices)

      setNotices(prevNotices => [...prevNotices, draggedNotice])
    }
  }

  const checkNoticeCondition = async (firstNoticeId, newNoticeId) => {
    try {
      const response = await fetch(`${ApiRoute}admin/checkCSVFormat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstNoticeId: firstNoticeId,
          newNoticeId: newNoticeId,
        }),
      })

      const responseText = await response.text()

      if (!response.ok) {
        throw new Error(`HTTP error, status = ${response.status}`)
      }

      const data = JSON.parse(responseText) // Convert text to JSON

      if (data.status === "success") {
        return true
      } else {
        console.error("API returned an error:", data.message)
        return false
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error)
      return false
    }
  }

  return (
    !props?.loading && (
      <div>
        {props.request.natureOfNotice !== "PROXY" && (
          <Toggle
            style={{ marginLeft: "85%" }}
            checkedChildren="EXPRESS"
            onChange={isChecked => {
              setExpress(isChecked)
              isChecked
                ? props?.setRequest({
                    ...props?.request,
                    pdfGenerationStrategy: "EXPRESS",
                  })
                : props?.setRequest({
                    ...props?.request,
                    pdfGenerationStrategy: "STANDARD",
                  })
            }}
            checked={
              props?.request?.pdfGenerationStrategy === "STANDARD"
                ? false
                : true || false
            }
            unCheckedChildren="STANDARD"
          />
        )}

        <Form method="post" onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="noticeType">Notice Type :</Label>
            <Input
              type="text"
              name="noticeType"
              id="noticeType"
              placeholder="Enter the name of the notice"
              value={props?.request?.notice_type || ""}
              disabled={true}
              onChange={e =>
                props?.setRequest({
                  ...props?.request,
                  notice_type: e.target.value,
                })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label className="mt-3" for="noticeDescription">
              Notice Description(max: 100 words) :
            </Label>
            <Input
              type="textarea"
              name="noticeDescription"
              id="noticeDescription"
              placeholder="Enter Notice Description"
              defaultValue={props?.request?.notice_desc || ""}
              onChange={e =>
                props?.setRequest({
                  ...props?.request,
                  notice_desc: e.target.value,
                })
              }
            />
          </FormGroup>
          {props?.request?.natureOfNotice === "USER" ? (
            <div>
              <FormGroup>
                <Label className="mt-3" for="draftNotice">
                  Draft Notice :
                </Label>
                <Editor
                  request={props?.request}
                  setRequest={props?.setRequest}
                  defaultValue={props?.request?.draft_notice_html || ""}
                  editType={true}
                />
                {props?.request?.pdfGenerationStrategy === "EXPRESS" ? (
                  <PdfViewer html={props?.request?.draft_notice_html} />
                ) : (
                  <Button
                    type="button"
                    className="my-4"
                    color="warning"
                    size="md"
                    onClick={() => handlePreview()}
                  >
                    Preview
                  </Button>
                )}
                {props?.request?.transmission_type !== "FILE" &&
                props?.request?.letterhead_configuration ? (
                  <Button
                    type="button"
                    className="my-4 mx-3"
                    color="primary"
                    size="md"
                    onClick={() => {
                      setOpen(true)
                      const userID = JSON.parse(
                        props?.request?.letterhead_configuration
                      )
                      getLetterHeadById(userID?.user_id)
                      console.log(props?.request?.letterhead_configuration)
                    }}
                  >
                    Select LetterHead
                  </Button>
                ) : (
                  <></>
                )}
                <LetterHeadSetting
                  open={open}
                  setOpen={closeModal}
                  allLetterHead={letterHead}
                  setRequest={updaterequest}
                />
              </FormGroup>
              <FormGroup>
                <Label
                  className="mt-3"
                  style={{ marginRight: "1em" }}
                  for="bulkUpload"
                >
                  View Bulk Format :
                </Label>
                <span className="">
                  <WhisperTooltip
                    placement="top"
                    trigger="hover"
                    tooltipMsg="View Bulk Format"
                  >
                    <a
                      target="_blank"
                      href={
                        RouteForShow +
                        "notice_bulk/" +
                        props?.request?.notice_bulk
                      }
                    >
                      <IconButton
                        size="sm"
                        appearance="primary"
                        color="green"
                        icon={<FileDownloadIcon />}
                      />
                    </a>
                  </WhisperTooltip>
                </span>
              </FormGroup>
              <p
                className="mt-4"
                style={{ color: "#ff0a16", width: "70%", fontSize: "1.2em" }}
              >
                Note : - For adding / deleting variable ( ex. --some_name-- )
                please make changes in above editor and also upload new csv
                below for the same.
              </p>
              <FormGroup>
                <Label className="mt-3" for="bulkUpload">
                  Upload Format for Bulk Upload :
                </Label>
                <FileUploader
                  name="file"
                  types={["csv"]}
                  onTypeError={e =>
                    e &&
                    props?.setRequest({
                      ...props?.request,
                      bulk_csv: null,
                    })
                  }
                  handleChange={e =>
                    props?.setRequest({
                      ...props?.request,
                      bulk_csv: e,
                    })
                  }
                />
              </FormGroup>

              <FormGroup className="mt-3">
                <Label for="linkExpire">Will Reply Link Expire ? :</Label>
                <samp className="px-3">
                  <Input
                    style={{ marginRight: "0.5em" }}
                    name="linkExpire"
                    type="radio"
                    checked={props?.request?.willLinkExpire == 1}
                    onChange={() => handleLinkExpireChange(1)}
                  />
                  <Label check>Yes</Label>
                </samp>
                <span>
                  <Input
                    style={{ marginRight: "0.5em" }}
                    name="linkExpire"
                    type="radio"
                    checked={props?.request?.willLinkExpire == 0}
                    onChange={() => handleLinkExpireChange(0)}
                  />
                  <Label check>No</Label>
                </span>
                {/* <Input type="select" name="linkExpire" id="linkExpire">
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </Input> */}
              </FormGroup>
              <FormGroup className="mt-3">
                <Label for="sendAs">Send As :</Label>
                <samp className="px-3">
                  <Input
                    style={{ marginRight: "0.5em", cursor: "pointer" }}
                    name="sendAs"
                    type="radio"
                    checked={parseInt(props?.request?.send_as) === 1}
                    onChange={() => handleSendAsChange(1)}
                  />
                  <Label check>Legal Notice</Label>
                </samp>
                <span>
                  <Input
                    style={{ marginRight: "0.5em", cursor: "pointer" }}
                    name="sendAs"
                    type="radio"
                    checked={parseInt(props?.request?.send_as) === 2}
                    onChange={() => handleSendAsChange(2)}
                  />
                  <Label check>Communication</Label>
                </span>
              </FormGroup>
              <FormGroup className="mt-3" inline>
                <Label for="transmission_type">Transmisson Type:</Label>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "20px" }}>
                    <Input
                      type="checkbox"
                      id="FILE"
                      checked={props?.request?.transmission_type === "FILE"}
                      onChange={e =>
                        props?.setRequest({
                          ...props?.request,
                          transmission_type: "FILE",
                        })
                      }
                      style={{ marginRight: "5px" }}
                    />
                    <Label for="FILE">FILE</Label>
                  </div>
                  <div style={{ marginRight: "20px" }}>
                    <Input
                      type="checkbox"
                      id="API"
                      checked={props?.request?.transmission_type === "API"}
                      onChange={e =>
                        props?.setRequest({
                          ...props?.request,
                          transmission_type: "API",
                        })
                      }
                      style={{ marginRight: "5px" }}
                    />
                    <Label for="API">API</Label>
                  </div>
                  <div style={{ marginRight: "20px" }}>
                    <Input
                      type="checkbox"
                      id="BOTH"
                      checked={props?.request?.transmission_type === "BOTH"}
                      onChange={e =>
                        props?.setRequest({
                          ...props?.request,
                          transmission_type: "BOTH",
                        })
                      }
                      style={{ marginRight: "5px" }}
                    />
                    <Label for="BOTH">BOTH</Label>
                  </div>
                </div>
              </FormGroup>
              <FormGroup className="mt-3" inline>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "20px" }}>
                    <Label for="font name">Font Name</Label>
                    <SelectPicker
                      value={props?.request?.fontUsed}
                      defaultValue={props?.request?.fontUsed}
                      block
                      className="selectMenu"
                      placeholder="Select Font"
                      data={[
                        {
                          label: "Freesans",
                          value: "freesans",
                        },
                        {
                          label: "Navi Body Font",
                          value: "navibodyfont",
                        },
                        {
                          label: "Kruti Dev",
                          value: "krutidev",
                        },
                        {
                          label: "Poppins",
                          value: "Poppins",
                        },
                        {
                          label: "Times New Roman",
                          value: "Times New Roman",
                        },
                      ]}
                      searchable={false}
                      onChange={e => {
                        props?.setRequest({ ...props?.request, fontUsed: e })
                      }}
                    />
                  </div>
                  <div style={{ marginRight: "20px" }}>
                    <Label for="font name for coborrower">
                      Font Name For Coborrower
                    </Label>
                    <SelectPicker
                      value={props?.request?.co_borrower_font}
                      defaultValue={props?.request?.co_borrower_font}
                      block
                      className="selectMenu"
                      placeholder="Select Font For Coborrower"
                      data={[
                        {
                          label: "Freesans",
                          value: "freesans",
                        },
                        {
                          label: "Navi Body Font",
                          value: "navibodyfont",
                        },
                        {
                          label: "Kruti Dev",
                          value: "krutidev",
                        },
                        {
                          label: "Poppins",
                          value: "Poppins",
                        },
                        {
                          label: "Times New Roman",
                          value: "Times New Roman",
                        },
                      ]}
                      searchable={false}
                      onChange={e => {
                        props?.setRequest({
                          ...props?.request,
                          co_borrower_font: e,
                        })
                      }}
                    />
                  </div>
                  <div style={{ marginRight: "20px" }}>
                    <Label for="font size">Font Size For Coborrower</Label>
                    <SelectPicker
                      value={props?.request?.co_borrower_font_size}
                      defaultValue={props?.request?.co_borrower_font_size}
                      block
                      className="selectMenu"
                      placeholder="Select Font Size"
                      data={[
                        {
                          label: "8",
                          value: "8pt",
                        },
                        {
                          label: "9",
                          value: "9pt",
                        },
                        {
                          label: "10",
                          value: "10pt",
                        },
                        {
                          label: "11",
                          value: "11pt",
                        },
                        {
                          label: "12",
                          value: "12pt",
                        },
                        {
                          label: "13",
                          value: "13pt",
                        },
                        {
                          label: "14",
                          value: "14pt",
                        },
                        {
                          label: "15",
                          value: "15pt",
                        },
                        {
                          label: "16",
                          value: "16pt",
                        },
                        {
                          label: "17",
                          value: "17pt",
                        },
                        {
                          label: "18",
                          value: "18pt",
                        },
                      ]}
                      searchable={false}
                      onChange={e => {
                        props?.setRequest({
                          ...props?.request,
                          co_borrower_font_size: e,
                        })
                      }}
                    />
                  </div>
                </div>
              </FormGroup>
              <FormGroup className="mt-3" inline>
                <Label for="communicationModes">Communication Modes:</Label>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "20px" }}>
                    <Input
                      type="checkbox"
                      id="email"
                      checked={parseInt(props?.request?.emailEnabled) === 1}
                      onChange={e =>
                        handleCommunicationModeChange(1, e.target.checked)
                      }
                      style={{ marginRight: "5px" }}
                    />
                    <Label for="email">Email</Label>
                  </div>
                  <div style={{ marginRight: "20px" }}>
                    <Input
                      type="checkbox"
                      id="sms"
                      checked={parseInt(props?.request?.smsEnabled) === 1}
                      onChange={e =>
                        handleCommunicationModeChange(2, e.target.checked)
                      }
                      style={{ marginRight: "5px" }}
                    />
                    <Label for="sms">SMS</Label>
                  </div>
                  <div style={{ marginRight: "20px" }}>
                    <Input
                      type="checkbox"
                      id="whatsapp"
                      checked={parseInt(props?.request?.whatsappEnabled) === 1}
                      onChange={e =>
                        handleCommunicationModeChange(3, e.target.checked)
                      }
                      style={{ marginRight: "5px" }}
                    />
                    <Label for="whatsapp">WhatsApp</Label>
                  </div>
                  <div>
                    <Input
                      type="checkbox"
                      id="rpad"
                      checked={parseInt(props?.request?.rpadEnabled) === 1}
                      onChange={e =>
                        handleCommunicationModeChange(4, e.target.checked)
                      }
                      style={{ marginRight: "5px" }}
                    />
                    <Label for="rpad">RPAD</Label>
                  </div>
                </div>
              </FormGroup>
              <FormGroup className="mt-3">
                <Label for="autoGeneratePdf">
                  System generated pdf required? :
                </Label>
                <samp className="px-3">
                  <Input
                    style={{ marginRight: "0.5em", cursor: "pointer" }}
                    name="autoGeneratePdf"
                    type="radio"
                    checked={parseInt(props?.request?.autoGeneratePdf) === 1}
                    onChange={() => handleAutoGeneratePdfChange(1)}
                  />
                  <Label check>Yes</Label>
                </samp>
                <span>
                  <Input
                    style={{ marginRight: "0.5em", cursor: "pointer" }}
                    name="autoGeneratePdf"
                    type="radio"
                    checked={parseInt(props?.request?.autoGeneratePdf) === 0}
                    onChange={() => handleAutoGeneratePdfChange(0)}
                  />
                  <Label check>No</Label>
                </span>
              </FormGroup>
              <FormGroup>
                <Label for="canvaSubtitle">Subtitle for Canva :</Label>
                <Input
                  type="text"
                  name="canvaSubtitle"
                  id="canvaSubtitle"
                  placeholder="Enter the Subtitle for Canva"
                  value={props?.request?.canvaSubtitle || ""}
                  onChange={e =>
                    props?.setRequest({
                      ...props?.request,
                      canvaSubtitle: e.target.value,
                    })
                  }
                />
              </FormGroup>

              <FormGroup className="mt-3">
                <Label for="enterpriseAbbr">Enterprise Abbr :</Label>
                <Input
                  type="text"
                  name="enterpriseAbbr"
                  id="enterpriseAbbr"
                  placeholder="Enter Enterprise Abbr"
                  value={props?.request?.enterpriseAbbr || ""}
                  onChange={e =>
                    props?.setRequest({
                      ...props?.request,
                      enterpriseAbbr: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup className="mt-3">
                <Label>
                  Fill below section only if separate whatsapp template required
                  for this notice.
                </Label>
              </FormGroup>
              <FormGroup>
                <Label>Template Prefix :</Label>
                <Input
                  type="text"
                  name="whatsappTemplatePrefix"
                  id="whatsappTemplatePrefix"
                  placeholder="Enter Whatsapp Template Prefix"
                  value={props?.request?.whatsappTemplatePrefix || ""}
                  onChange={e =>
                    props?.setRequest({
                      ...props?.request,
                      whatsappTemplatePrefix: e.target.value,
                    })
                  }
                />
              </FormGroup>

              <FormGroup className="mt-3" inline>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "20px" }}>
                    <Input
                      type="checkbox"
                      id="sent_whatsapp_text"
                      checked={
                        parseInt(props?.request?.sent_whatsapp_text) === 1
                      }
                      onChange={e =>
                        handleSeparateWhatsappModeChange(1, e.target.checked)
                      }
                      style={{ marginRight: "5px" }}
                    />
                    <Label for="send_whatsapp_text">Send Whatsapp Text</Label>
                  </div>
                  <div style={{ marginRight: "20px" }}>
                    <Input
                      type="checkbox"
                      id="sent_whatsapp_pdf"
                      checked={
                        parseInt(props?.request?.sent_whatsapp_pdf) === 1
                      }
                      onChange={e =>
                        handleSeparateWhatsappModeChange(2, e.target.checked)
                      }
                      style={{ marginRight: "5px" }}
                    />
                    <Label for="send_whatsapp_pdf">Send Whatsapp Pdf</Label>
                  </div>

                  <div style={{ marginRight: "20px" }}>
                    <Input
                      type="checkbox"
                      id="use_organization_name"
                      checked={
                        parseInt(props?.request?.use_organization_name) === 1
                      }
                      onChange={e =>
                        handleSeparateWhatsappModeChange(3, e.target.checked)
                      }
                      style={{ marginRight: "5px" }}
                    />
                    <Label for="use_organization_name">
                      Use Organization Name
                    </Label>
                  </div>
                </div>
              </FormGroup>

              <FormGroup className="mt-3">
                <Label>
                  Whatsapp text variable (each variable comma separated without
                  space and for notice variable use _ if variable have space in
                  between):
                </Label>
                <Input
                  type="text"
                  name="whatsappTextVariable"
                  id="whatsappTextVariable"
                  placeholder="nid,rlink,footernote,Date_of_notice"
                  value={props?.request?.whatsappTextVariable || ""}
                  onChange={e =>
                    props?.setRequest({
                      ...props?.request,
                      whatsappTextVariable: e.target.value,
                    })
                  }
                />
              </FormGroup>

              <FormGroup className="mt-3">
                <Label>
                  Whatsapp pdf variable (each variable comma separated without
                  space and for notice variable use _ if variable have space in
                  between):
                </Label>
                <Input
                  type="text"
                  name="whatsappPdfVariable"
                  id="whatsappPdfVariable"
                  placeholder="nid,Date_of_notice"
                  value={props?.request?.whatsappPdfVariable || ""}
                  onChange={e =>
                    props?.setRequest({
                      ...props?.request,
                      whatsappPdfVariable: e.target.value,
                    })
                  }
                />
              </FormGroup>

              <p style={{ margin: "0", padding: "1em 0" }}>
                Fill below section only if separate email template required for
                this notice.
              </p>
              <FormGroup>
                <Label className="mt-3" for="noticeDescription">
                  Email template config ( for separate emails only ) :
                </Label>
                <Input
                  type="textarea"
                  name="email_template_config"
                  id="email_template_config"
                  value={props?.request?.email_template_config || ""}
                  placeholder="Enter Email Template Config"
                  onChange={e =>
                    props?.setRequest({
                      ...props?.request,
                      email_template_config: e.target.value,
                    })
                  }
                />
              </FormGroup>
            </div>
          ) : (
            <DragDropContext onDragEnd={onDragEnd}>
              <div className="drag-drop-context">
                <Droppable droppableId="notices">
                  {provided => (
                    <div
                      className="draggable-container"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      <Label className="mt-3" for="noticeDescription">
                        Available Notices
                      </Label>
                      {notices.map((notice, index) => (
                        <Draggable
                          key={notice.notice_id} // Ensure each notice has a unique key
                          draggableId={`${notice.notice_id}`} // Draggable ID must be unique and a string
                          index={index} // Index should be properly set as a consecutive number
                        >
                          {provided => (
                            <div
                              className="draggable-item"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <span>{notice.notice_id}</span> -{" "}
                              <span>{notice.notice_type}</span>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>

                <Droppable droppableId="addedNotices">
                  {provided => (
                    <div
                      className="draggable-container"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      <Label className="mt-3" for="noticeDescription">
                        Add Notice
                      </Label>
                      {addedNotices.map((notice, index) => (
                        <Draggable
                          key={notice.notice_id} // Again, unique key for each draggable item
                          draggableId={`${notice.notice_id}`} // Unique string ID for draggable
                          index={index} // Index must be consecutive
                        >
                          {provided => (
                            <div
                              className="draggable-item"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <span>{notice.notice_id}</span> -{" "}
                              <span>{notice.notice_type}</span>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>

                <div>{/* Your form content here */}</div>
              </div>
            </DragDropContext>
          )}
          <Button className="my-4" color="primary" size="md" type="submit">
            Submit
          </Button>{" "}
        </Form>
      </div>
    )
  )
}

const mapStateToProps = state => ({
  token: state.Login.token,
})

export default connect(mapStateToProps)(NoticeForm)
